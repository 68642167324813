import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h4>Trailers - Full Kitchen Advantages </h4>
      <h1 className="gradient__text">We Specialize on the following <br /> Food Trailers</h1>

      <p className="gpt3_possibility__text_padding"> Taco Trailers.</p>
      <p className="gpt3_possibility__text_padding"> Haburger Trailiers.</p>
      <p className="gpt3_possibility__text_padding"> BBQ Trailers.</p>
      <p className="gpt3_possibility__text_padding"> Asian Food Trailers.</p>
      <p className="gpt3_possibility__text_padding"> Pizza Trailers.</p>
      <p className="gpt3_possibility__text_padding"> Rostisiere Chicken Trailers.</p>
      <p className="gpt3_possibility__text_padding"> Seafood Trailers.</p>
      <p className="gpt3_possibility__text_padding"> Soft Serve Trailers.</p>

   
        <p></p>
      <h4>We look forward to bringing your dreams into reality withing the perfect time frame and budget.</h4>
    </div>
  </div>
);

export default Possibility;
