import React from 'react';
import './header.css';
import ai from '../../assets/trailer.png';
import  Feature  from '../../components/feature/Feature';
import '../../containers/whatGPT3/whatGPT3.css';

const Header = () => {
    return(
    <div>


        <div className="gpt3__header section_padding" id="home"> 
            <div className="gpt3__header-image">
                <img src={ai} alt="aia" />
            </div> 
        </div>


        <div className="gpt3__whatgpt3 section__margin" id="home">

        <div className="gpt3__whatgpt3-heading">
    <h1 className="gradient__text">West Coast Concession Trailers</h1>
    
    </div>


            <div className="gpt3__whatgpt3-container">
                <Feature title="SKILLED CRAFTSMANSHIP" text="Our skilled craftman are well vested in the industry. We provide state of the art emvironment so our final product may reflact so. 
                Our craftman's product knowledge encompasses measuring, cutting, welding, installing, building to say a few functions." />
                <Feature title="TOP QUIALITY / APPROVED MATERIALS" text="We are using top of the line materials NSF approved.
                Every appliance we install is NSF certified and is ready to pass local health inspections as required by law. 
                We bild every Food Trailer with the best materials available in the market and the customer in mind." />
            </div>  
        </div>


    </div>


    )
}

export default Header;