import React from 'react';
import './header2.css';
import ai from '../../assets/aia.png';
import  Feature  from '../../components/feature/Feature';
import '../../containers/whatGPT3/whatGPT3.css';

const Header2 = () => {                              
    return(
    <div>


        <div className="gpt3__header section_padding" id="Header2"> 
            <div className="gpt3__header-image">
                <img src={ai} alt="aia" />
            </div> 
        </div>


       


    </div>


    )
}

export default Header2;