import React from 'react';

import './footer.css';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Call Now</h1>
      <h1 className="gradient__text">559.545.7910</h1>
      <h1 className="gradient__text">FINANCING AVAILABLE</h1>
      
    </div>

    {/* <div className="gpt3__footer-btn">
      <p><a href="https://boredskullyachtclub.com/mint">APPLY FOR CREDIT HERE ONLINE</a></p>
    </div> */}

    <div className="gpt3__footer-copyright">
    <p>West Coast Concession Trailers</p>
      <p>325 S Schnoor Ave. #133</p>
      <p>Madera, Ca 93737</p>
      <p>westcoastconcessiontrailers@gmail.com</p>
      <p>@2023 WCCT. All rights reserved.</p>
    </div>

  </div>
);

export default Footer;
