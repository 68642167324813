import React from 'react';
import  Feature  from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
   

   <div className="gpt3__whatgpt3-heading">
    <h1 className="gradient__text">High Quality, Unbeatable Service</h1>
    
    </div>

    <div className="gpt3__whatgpt3-container">
      <Feature title="Floor Layouts for Trucks and Trailers" text="All trailers are custom built and designed for you, including the interior layout! Enjoy a sneak peak at our concession trailer and food truck floor options, but remember – this is not our total selection! We build and customize trailers, trucks and carts to make your dreams a reality!" />
      
      
      <Feature title="Food Trucks and Concession Trailers" text="We fully customize each food truck, custom food cart and concession trailer for each client and every business. From the exterior color to the professional graphics, you choose how you want yours to look. View our gallery for past projects, but remember – we design for you!

" />




      <Feature title="Interior and Concession Equipment" text="The interior of each concession food trailer and mobile food truck is designed and outfitted with the equipment suitable and necessary for your business. Whether you’re cooking hot dogs or flavoring shaved ice, we have you covered when it comes to concession equipment." />
    </div>
  </div>
);

export default WhatGPT3